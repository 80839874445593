import { createTheme } from 'baseui';

const primitives = {
  primaryFontFamily: '"CeraPro", "Helvetica Neue", Helvetica, Arial, sans-serif',
};

const overrides = {

};

export const onepagerTheme = createTheme(primitives, overrides);