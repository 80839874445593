import '../styles/globals.css'
import '../styles/index.css'
import '../styles/app.css'
import '../styles/fonts.css'
import '../styles/builder.css'
import '../styles/onepager/hero.css'
import '../styles/onepager/grid.css'
import '../styles/onepager/shorttext.css'
import '../styles/onepager/tasks.css'
import '../styles/onepager/table.css'
import '../styles/quill-prose-bubble.css'
import '../styles/embed.css'

import '@/public/js/redactorx.css'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/effect-fade/effect-fade.scss'

// baseweb
import { Client, Server } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { LightTheme, BaseProvider, styled } from 'baseui'
import { onepagerTheme } from '@/utils/baseweb-theme'

// scroll top on router push
import useRouterScroll from '@/utils/scrollTop'

import { SegmentProvider } from 'react-segment-hooks'
import Head from 'next/head'

const getHydrateClass = () =>
  document.getElementsByClassName('_styletron_hydrate_')

const styletron =
  typeof window === 'undefined'
    ? new Server()
    : new Client({
        hydrate: getHydrateClass(),
      })

// import * as Sentry from '@sentry/node'
// import '../utils/sentry'

import * as Sentry from '@sentry/node'
import { CaptureConsole } from '@sentry/integrations'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id })
    //   }
    //   return event
    // },
  })
}

export default function App({ Component, pageProps, err }) {
  const Layout = Component.Layout || EmptyLayout
  useRouterScroll()

  // const { pathname } = useRouter()

  // useEffect(() => {
  //   // some browsers (like safari) may require a timeout to delay calling this
  //   // function after a page has loaded; otherwise, it may not update the position
  //   window.scrollTo(0, 0)
  // }, [pathname])

  return (
    <StyletronProvider value={styletron}>
      <Head>
        {/* SEGMENT.IO */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.log&&console.log("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
            analytics.load("V4DXIqrnRlZ6pAZkY1g1VD9OfNRKeMZT");
            analytics.page();
            }}();
            `,
          }}
        /> */}
      </Head>

      <BaseProvider theme={onepagerTheme}>
        <Layout {...pageProps}>
          <Component {...pageProps} err={err} />
        </Layout>
      </BaseProvider>
    </StyletronProvider>
  )
}

const EmptyLayout = ({ children }) => <>{children}</>
